import { useRouter } from 'next/router';
import { Flex, Button, Box, Text } from '~components';

const NotFound = () => {
  const router = useRouter();
  return (
    <Flex
      flex={1}
      height="100vh"
      backgroundColor="#FFF"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      position="relative"
    >
      <Box
        position="absolute"
        mx="auto"
        top="32px"
        onClick={() => router.replace('/')}
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="24px" lineHeight="100%" fontWeight="bold">
          eyehelp
        </Text>
      </Box>
      <Flex
        maxW="520px"
        flexDir="column"
        backgroundColor="professional.N300"
        p="64px"
      >
        <Text
          fontFamily="mono"
          fontWeight="bold"
          fontSize="32px"
          color="professional.N900"
          mb="24px"
        >
          Oops.
        </Text>
        <Text
          fontFamily="mono"
          fontWeight="medium"
          fontSize="24px"
          lineHeight="150%"
          color="professional.N900"
          mb="24px"
        >
          Het lijkt erop dat wij de pagina die u zocht niet kunnen vinden.
        </Text>
        <Button width="fit-content" onClick={() => router.replace('/')}>
          Terug naar homepagina
        </Button>
      </Flex>
    </Flex>
  );
};

export default NotFound;
